import React from 'react'
import { Box, Flex } from 'theme-ui'
import { GlobalLayout } from '../global'

const MainLayout = React.memo(({ children }) => {
  return (
    <GlobalLayout withMenu={true}>
      <Flex
        sx={{
          justifyContent: 'center',
          mt: [4, 5],
          px: [4, 5],
          width: '100%',
        }}
      >
        <Box sx={{ flexBasis: '840px' }}>{children}</Box>
      </Flex>
    </GlobalLayout>
  )
})
MainLayout.displayName = 'MainLayout'

export { MainLayout }
