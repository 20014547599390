/** @jsx jsx */
import React from 'react'
import { Box } from 'theme-ui'
import { jsx } from 'theme-ui'
import { textGradientMixin } from './TextGradient'

interface StylizedTitleProps {
  className?: string
  as?: 'h1' | 'h2'

  children: React.ReactNode
}

export const StylizedTitle = React.memo<StylizedTitleProps>(props => (
  <Box
    className={props.className}
    as={props.as}
    sx={{
      fontFamily: 'heading',
      fontWeight: 'heading',
      letterSpacing: [1, 2],
      '&::before': {
        content: '"# "',
        ...textGradientMixin,
      },
    }}
  >
    {props.children}
  </Box>
))
