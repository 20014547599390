/** @jsx jsx */
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { Box, Flex, jsx } from 'theme-ui'
import { TextGradient } from './TextGradient'

interface ItemsMatrixProps {
  items: any
  withExcerpt?: boolean
}

export const ItemsMatrix = React.memo<ItemsMatrixProps>(props => (
  <Flex sx={{ flexWrap: 'wrap', alignItems: 'flex-start', m: -2 }}>
    {props.items.map((skill: any) => (
      <Link
        to={`/${
          skill.node.frontmatter.type === 'project' ? 'projects' : 'skills'
        }/${skill.node.frontmatter.slug}`}
        key={skill.node.frontmatter.slug}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          p: 2,
          flexBasis: ['100%', '50%'],
          minWidth: 0,
          textDecoration: 'none',
        }}
      >
        <Flex sx={{ position: 'relative' }}>
          <GatsbyImage
            alt="Image d'illustration"
            image={
              skill.node.frontmatter.featuredImage.childImageSharp
                .gatsbyImageData
            }
          />

          <Flex
            sx={{
              alignItems: 'center',
              position: 'absolute',
              p: 2,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, .3)',
              color: 'white',
              fontFamily: 'heading',
              fontWeight: 'heading',
              fontSize: [0, 1],
              letterSpacing: [1, 2],
            }}
          >
            {skill.node.frontmatter.title}
          </Flex>
        </Flex>

        {props.withExcerpt && (
          <Box
            sx={{
              fontFamily: 'body',
              fontWeight: 'body',
              letterSpacing: 1,
              lineHeight: 2,
              color: 'text',
              mt: 3,
            }}
          >
            {skill.node.excerpt} <TextGradient>En lire plus</TextGradient>
          </Box>
        )}
      </Link>
    ))}
  </Flex>
))
