/** @jsx jsx */
import * as React from 'react'
import { Box } from 'theme-ui'
import { jsx } from 'theme-ui'
import { ItemsMatrix } from '../../components/ItemsMatrix'
import { SEO } from '../../components/SEO'
import { StylizedTitle } from '../../components/StylizedTitle'
import { SkillsQuery } from '../../types/gatsby-queries'
import { MainLayout } from '../../layouts/main'
import { PageProps } from '../../utils/types'

const Page = React.memo<PageProps<SkillsQuery>>(({ data, location }) => {
  const { allMdx } = data
  const { edges } = allMdx
  const filterSkillsByType = (type: string) =>
    edges.filter(edge => edge.node.frontmatter.skillType === type)

  const humanSkills = filterSkillsByType('human')
  const technicalSkills = filterSkillsByType('technical')

  return (
    <>
      <SEO title="Compétences" location={location} />
      <MainLayout>
        <StylizedTitle
          as="h1"
          sx={{
            fontSize: [3, 4],
          }}
        >
          Compétences
        </StylizedTitle>

        <Box
          as="h2"
          sx={{
            fontFamily: 'heading',
            fontWeight: 'heading',
            fontSize: [2, 3],
            letterSpacing: [1, 2],
            my: 4,
          }}
        >
          Techniques
        </Box>

        <ItemsMatrix withExcerpt items={technicalSkills} />

        <Box
          as="h2"
          sx={{
            fontFamily: 'heading',
            fontWeight: 'heading',
            fontSize: [2, 3],
            letterSpacing: [1, 2],
            my: 4,
          }}
        >
          Humaines
        </Box>

        <ItemsMatrix withExcerpt items={humanSkills} />
      </MainLayout>
    </>
  )
})
Page.displayName = 'Page'

export default Page
